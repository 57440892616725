<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <CareerItem
        v-for="career in getCareers"
        :key="career._id"
        :career="career"
      />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CareerItem from "./components/careerItem";
export default {
  data() {
    return {
      options: [
        {
          nome: 'Cadastrar Licença',
          action: () => this.$router.push('/careers/view')
        }
      ]
    }
  },
  components: {
    CareerItem
  },
  computed: {
    ...mapGetters(["getCareers"])
  },
  methods: {
    ...mapActions(["listCareers"])
  },
  created() {
    this.listCareers();
  }
};
</script>
