<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-3">
    <v-card @click="$router.push('/careers/view/'+career._id)" elevation="24">
      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide, i) in career.images" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list two-line dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte" style="font-size: 14pt;"
              >Licença</v-list-item-title
            >
            <v-list-item-subtitle class="fonte font-weight-thin orange--text">{{
              career.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!priceless"
              @click="openPurchase({ ...career, purchase_type: 'career' })"
              class="mt-3"
              small
              :color="$theme.primary"
              rounded
              outlined
              dark
            >
              <span class="fonte">$ {{ career.price }}</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- <v-divider :key="i"></v-divider>
          <h3 class="fonte text-center my-2">BENEFÍCIOS</h3>
          <v-divider :key="i"></v-divider>
          <template v-for="(item, i) in career.system_actions">
            <v-list-item :key="item">
              <v-list-item-content>
                <h5 class="fonte text-center orange--text">{{ item.description }}</h5>
              </v-list-item-content>
              <v-list-item-actions>
                <v-icon size="15" color="green">mdi-check</v-icon>
              </v-list-item-actions>
            </v-list-item>
            <v-divider :key="i"></v-divider>
          </template> -->
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["career", "fullscreen", "priceless"],
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
